import React,{Component} from 'react';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import  {PorfolioSlider,GallerySection,GallerySliderCol,
    GalleryInnerContainer,PrevImg,NextImg,NextPrev,ImgButton} from './gallery.style';
import {SectionHeading} from '../Common/common.style';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GatsImg from "gatsby-image";
import { StaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";


class Gallery extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            infinite: true,
            arrows:true,
            slidesToShow: 3,
            rows: 2,
            slidesPerRow: 1,
            speed: 1000,
            responsive: [
               
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                }
            ]
        };

        return(
                <GallerySection id="galleryContainer">
                    <Container>
                        <Row>
                            <Col md="12">
                                <Fade bottom delay={1*this.props.interior2Json.Gallery.DelayConstant}>
                                    <SectionHeading>{this.props.interior2Json.Gallery.SectionHeading}</SectionHeading>
                                </Fade>
                            </Col>
                        </Row>

                    </Container>
                    <Container>
                        <PorfolioSlider>
                            <Slider ref={c => (this.slider = c)} {...settings}>
                            {
                                this.props.interior2Json.Gallery.Slider.map((item,idx) => {
                                return <Fade bottom delay={(idx+1)*this.props.interior2Json.Gallery.DelayConstant}><GallerySliderCol>
                                        <GalleryInnerContainer>
                                            <GatsImg 
                                                fluid={item.GallerySliderImg.childImageSharp.fluid} 
                                                className="GallerySliderImg"
                                                alt=""
                                            />
                                        </GalleryInnerContainer>
                                    </GallerySliderCol>
                                </Fade>
                                })
                            }
                            </Slider>
                        </PorfolioSlider>
                        <NextPrev>
                            <ImgButton onClick={this.previous} aria-label="Prev Button">
                                <PrevImg src={this.props.interior2Json.Gallery.PrevImg} alt=""/>
                            </ImgButton>
                            <ImgButton onClick={this.next} aria-label="Next Button">
                                <NextImg src={this.props.interior2Json.Gallery.NextImg} alt=""/>
                            </ImgButton>
                        </NextPrev>
                    </Container>
                </GallerySection>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                    interior2Json{
                        Gallery{
                            SectionHeading
                            Slider{
                                GallerySliderImg{
                                    childImageSharp{
                                        fluid(quality: 100){
                                        ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                            PrevImg
                            NextImg
                        }
                    }
                }
            
        `}
        render={(data) => (
            <Gallery interior2Json={data.interior2Json}/>
        )}
    />
)
