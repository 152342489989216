import React, { Component } from "react"
import {
  BannerSection,
  BannerWrapper,
  BannerContents,
  BannerContentLeft,
  BannerContentRight,
  BannerBtn,
  BannerPara,
  RightArrowIcon,
  LeftArrowIcon,
  BannerH3,
  BannerH4,
  NextPrev,
} from "./banner.style"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Fade from "react-reveal/Fade"
import { StaticQuery, graphql } from "gatsby"
import GatsImg from "gatsby-image"

class Banner extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  next() {
    this.slider.slickNext()
  }

  previous() {
    this.slider.slickPrev()
  }

  render() {
    var settings = {
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    }

    return (
      <BannerSection id="homeContainer">
        <BannerWrapper>
          <BannerContents>
            <BannerContentLeft>
              <Fade
                bottom
                delay={1 * this.props.interior2Json.Banner.DelayConstant}
              >
                <div>
                  <BannerH3>
                    {this.props.interior2Json.Banner.BannerH3}
                  </BannerH3>
                  <br />
                  <BannerPara>
                    {this.props.interior2Json.Banner.BannerH4.map(
                      (item, idx) => {
                        return <li key={idx}>{item}</li>
                      }
                    )}
                  </BannerPara>
                  <BannerPara>
                    {this.props.interior2Json.Banner.BannerPara}
                  </BannerPara>
                  <BannerBtn href="mailto:kontakt@softwarefuerunternehmen.de">
                    {this.props.interior2Json.Banner.BannerBtn}
                  </BannerBtn>
                </div>
              </Fade>
            </BannerContentLeft>

            <BannerContentRight>
              <Fade
                bottom
                delay={1 * this.props.interior2Json.Banner.DelayConstant}
              >
                <Slider ref={c => (this.slider = c)} {...settings}>
                  <GatsImg
                    fluid={
                      this.props.interior2Json.Banner.BannerImage0
                        .childImageSharp.fluid
                    }
                    className="BannerImage"
                    alt=""
                    imgStyle={{
                      marginBottom: "0px",
                    }}
                  />
                  <GatsImg
                    fluid={
                      this.props.interior2Json.Banner.BannerImage1
                        .childImageSharp.fluid
                    }
                    className="BannerImage"
                    alt=""
                    imgStyle={{
                      marginBottom: "0px",
                    }}
                  />
                  <GatsImg
                    fluid={
                      this.props.interior2Json.Banner.BannerImage2
                        .childImageSharp.fluid
                    }
                    className="BannerImage"
                    alt=""
                    imgStyle={{
                      marginBottom: "0px",
                    }}
                  />
                  <GatsImg
                    fluid={
                      this.props.interior2Json.Banner.BannerImage3
                        .childImageSharp.fluid
                    }
                    className="BannerImage"
                    alt=""
                    imgStyle={{
                      marginBottom: "0px",
                    }}
                  />
                  <GatsImg
                    fluid={
                      this.props.interior2Json.Banner.BannerImage4
                        .childImageSharp.fluid
                    }
                    className="BannerImage"
                    alt=""
                    imgStyle={{
                      marginBottom: "0px",
                    }}
                  />
                  <GatsImg
                    fluid={
                      this.props.interior2Json.Banner.BannerImage5
                        .childImageSharp.fluid
                    }
                    className="BannerImage"
                    alt=""
                    imgStyle={{
                      marginBottom: "0px",
                    }}
                  />
                </Slider>
              </Fade>
              <NextPrev>
                <LeftArrowIcon
                  onClick={this.previous}
                  src={this.props.interior2Json.Banner.PrevImg}
                />
                <RightArrowIcon
                  onClick={this.next}
                  src={this.props.interior2Json.Banner.NextImg}
                />
              </NextPrev>
            </BannerContentRight>
          </BannerContents>
        </BannerWrapper>
      </BannerSection>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        interior2Json {
          Banner {
            BannerBtn
            BannerH3
            BannerH4
            BannerImage0 {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            BannerImage1 {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            BannerImage2 {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            BannerImage3 {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            BannerImage4 {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            BannerImage5 {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            DelayConstant
            BannerPara
            PrevImg
            NextImg
          }
        }
      }
    `}
    render={data => <Banner interior2Json={data.interior2Json} />}
  />
)
