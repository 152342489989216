import React from "react"
import { Container, Row, Col } from "@bootstrap-styled/v4"
import {
  FooterSection,
  FooterCol,
  FooterTitle,
  FooterPara,
  FooterLogo,
  FooterUl,
  FooterLi,
  FooterMenu,
  FooterSocial,
  BottomFooterPara,
  BottomLink,
  FbIcon,
  TwitterIcon,
  LinkedinIcon,
  InstaIcon,
  BottomFooterWrapper,
} from "./footer.style"

import ScrollSpy from "react-scrollspy"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { useStaticQuery, graphql } from "gatsby"
import Fade from "react-reveal/Fade"

const Footer = () => {
  const JSONData = useStaticQuery(graphql`
    query {
      interior2Json {
        Footer {
          DelayConstant
          FooterCol1 {
            FooterLogo
            FooterPara {
              Para
            }
            FooterSocial {
              FooterSocialImg
            }
          }
          FooterCol2 {
            FooterTitle
            FooterPara {
              Para
            }
          }
          FooterCol3 {
            FooterTitle
            FooterUl {
              FooterMenu
            }
          }
          FooterCol4 {
            FooterTitle
            Items
            FooterUl {
              IsLink
              Target
              FooterMenu
              Href
            }
          }
          BottomFooterPara
          BottomFooterName
          BottomFooterLink
        }
      }
    }
  `)

  return (
    <div>
      <FooterSection>
        <Container>
          <Fade Bottom delay={JSONData.interior2Json.Footer.DelayConstant}>
            <Row>
              <Col md="3">
                <FooterCol>
                  <FooterLogo
                    src={JSONData.interior2Json.Footer.FooterCol1.FooterLogo}
                    alt=""
                  />
                  {JSONData.interior2Json.Footer.FooterCol1.FooterPara.map(
                    (item, idx) => {
                      return (
                        <FooterPara key={idx}>
                          {item.Link ? (
                            <a href={item.Link}>{item.Para}</a>
                          ) : (
                            item.Para
                          )}
                        </FooterPara>
                      )
                    }
                  )}
                  {/*
                                <FooterSocial href="#" aria-label="Social Link">
                                    <FbIcon/>
                                </FooterSocial>
                                <FooterSocial href="#" aria-label="Social Link">
                                    <TwitterIcon/>
                                </FooterSocial>
                                <FooterSocial href="#" aria-label="Social Link">
                                    <LinkedinIcon/>
                                </FooterSocial>
                                <FooterSocial href="#" aria-label="Social Link">
                                    <InstaIcon/>
                                    </FooterSocial>
                                    */}
                </FooterCol>
              </Col>
              <Col md="3">
                <FooterCol>
                  <FooterTitle>
                    {JSONData.interior2Json.Footer.FooterCol2.FooterTitle}
                  </FooterTitle>
                  {JSONData.interior2Json.Footer.FooterCol2.FooterPara.map(
                    (item, idx) => {
                      return <FooterPara key={idx}>{item.Para}</FooterPara>
                    }
                  )}
                </FooterCol>
              </Col>
              <Col md="3">
                <FooterCol>
                  <FooterTitle>
                    {JSONData.interior2Json.Footer.FooterCol3.FooterTitle}
                  </FooterTitle>
                  <FooterUl>
                    {JSONData.interior2Json.Footer.FooterCol3.FooterUl.map(
                      (item, idx) => {
                        return (
                          <FooterLi key={idx}>
                            <FooterMenu>{item.FooterMenu}</FooterMenu>
                          </FooterLi>
                        )
                      }
                    )}
                  </FooterUl>
                </FooterCol>
              </Col>
              <Col md="3">
                <FooterCol>
                  <FooterTitle>
                    {JSONData.interior2Json.Footer.FooterCol4.FooterTitle}
                  </FooterTitle>
                  <ScrollSpy
                    offset={-59}
                    items={JSONData.interior2Json.Footer.Items}
                    currentClassName="is-current"
                  >
                    {JSONData.interior2Json.Footer.FooterCol4.FooterUl.map(
                      (item, idx) => {
                        return item.IsLink ? (
                          <FooterLi key={idx}>
                            <a href={item.Href} target={item.Target || "_self"}>
                              {item.FooterMenu}
                            </a>
                          </FooterLi>
                        ) : (
                          <FooterLi key={idx}>
                            <AnchorLink offset={55} href={item.Href}>
                              {item.FooterMenu}
                            </AnchorLink>
                          </FooterLi>
                        )
                      }
                    )}
                  </ScrollSpy>
                </FooterCol>
              </Col>
            </Row>
          </Fade>
        </Container>
      </FooterSection>

      <BottomFooterWrapper>
        <Container>
          <Row>
            <Fade bottom delay={JSONData.interior2Json.Footer.DelayConstant}>
              <Col md="12">
                <BottomFooterPara>
                  {JSONData.interior2Json.Footer.BottomFooterPara}
                  <BottomLink
                    rel="noreferrer"
                    href={JSONData.interior2Json.Footer.BottomFooterLink}
                    target="_blank"
                  >
                    {JSONData.interior2Json.Footer.BottomFooterName}
                  </BottomLink>
                </BottomFooterPara>
              </Col>
            </Fade>
          </Row>
        </Container>
      </BottomFooterWrapper>
    </div>
  )
}

export default Footer
