import {createGlobalStyle} from 'styled-components';
const GlobalStyle = createGlobalStyle`

    body{
        font-family: 'Hind', sans-serif;
        font-weight:400;
        overflow:hidden;
    }
    h1,h2,h3,h4,h5,h6{
        font-family: 'Barlow', sans-serif;
        font-weight: 700;
    }
    a{
        font-size:16px;
    }
`;

export default GlobalStyle;