import React from "react"
import { Container, Col } from "@bootstrap-styled/v4"
import {
  AboutSection,
  AboutLeft,
  AboutRight,
  AboutHeading,
  AboutDesc,
  AboutBtn,
  AboutRow,
} from "./about.style"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"
import GatsImg from "gatsby-image"

const About = () => {
  const JSONData = useStaticQuery(graphql`
    query {
      interior2Json {
        About {
          DelayConstant
          AboutLeftImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          AboutHeading
          AboutDesc
          AboutBtn
        }
      }
    }
  `)

  return (
    <AboutSection id="aboutContainer">
      <Container>
        <AboutRow>
          <Col md="6">
            <Fade left delay={1 * JSONData.interior2Json.About.DelayConstant}>
              <AboutLeft>
                <GatsImg
                  fluid={
                    JSONData.interior2Json.About.AboutLeftImage.childImageSharp
                      .fluid
                  }
                  className="AboutLeftImage"
                  objectFit="contain"
                  alt=""
                />
              </AboutLeft>
            </Fade>
          </Col>
          <Col md="6">
            <AboutRight>
              <Fade
                right
                delay={1 * JSONData.interior2Json.About.DelayConstant}
              >
                <AboutHeading>
                  {JSONData.interior2Json.About.AboutHeading}
                </AboutHeading>
              </Fade>
              <Fade
                right
                delay={2 * JSONData.interior2Json.About.DelayConstant}
              >
                <AboutDesc>{JSONData.interior2Json.About.AboutDesc}</AboutDesc>
              </Fade>
              <Fade
                right
                delay={3 * JSONData.interior2Json.About.DelayConstant}
              >
                <AboutBtn href="mailto:kontakt@softwarefuerunternehmen.de">
                  {JSONData.interior2Json.About.AboutBtn}
                </AboutBtn>
              </Fade>
            </AboutRight>
          </Col>
        </AboutRow>
      </Container>
    </AboutSection>
  )
}

export default About
