import React,{Component} from 'react';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import {SectionHeadingCustom,TestimonialsSection,TestimonialsContainer,CardOuterWrapper,CardProfileLayout,
    QuoteImg,ProfilePicLayout,ProfileNameLayout,ProfileName,Designation,ProfileLayoutWrapper,
    SliderWrapper,TestimonialHeading,Testimonial,PrevImg,NextImg,NextPrev} from './testimonials.style';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import GatsImg from "gatsby-image";

class Testimonials extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      initialSlide: 1
                    }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                }
            ]
        };
        
        return(
            <TestimonialsSection id="testimonialsContainer">
                <Container>
                    <Row>
                        <Col md="12">
                            <Fade bottom delay={1*this.props.interior2Json.Testimonials.DelayConstant}>
                                <SectionHeadingCustom>{this.props.interior2Json.Testimonials.TestimonialHeading}</SectionHeadingCustom>
                            </Fade>
                        </Col>
                    </Row>
                </Container>

                <Fade bottom delay={1*this.props.interior2Json.Testimonials.DelayConstant}>
                    <TestimonialsContainer>
                        <SliderWrapper>
                            <QuoteImg src={this.props.interior2Json.Testimonials.QuoteImg} alt=""/>
                            <Slider ref={c => (this.slider = c)} {...settings}>
                            {
                                this.props.interior2Json.Testimonials.CardProfileLayout.map((item,idx) => {
                                    return <CardOuterWrapper>
                                        <TestimonialHeading>
                                            {item.TestimonailHeading}
                                        </TestimonialHeading>
                                        <Testimonial>
                                            {item.Testimonial}
                                        </Testimonial>
                                        <ProfileLayoutWrapper>
                                        <CardProfileLayout>
                                            <ProfilePicLayout>
                                                <GatsImg 
                                                    fluid={item.ProfilePic.childImageSharp.fluid} 
                                                    className="ProfilePic"
                                                    alt=""
                                                />
                                            </ProfilePicLayout>
                                            <ProfileNameLayout>
                                                <ProfileName>
                                                    {item.ProfileName}
                                                </ProfileName>
                                                <Designation>
                                                    {item.Designation}
                                                </Designation>
                                            </ProfileNameLayout>
                                        </CardProfileLayout>
                                        </ProfileLayoutWrapper>
                                    </CardOuterWrapper>
                                })
                            }
                            </Slider>
                            <NextPrev>
                                <button onClick={this.previous} aria-label="Prev Button">
                                    <PrevImg src={this.props.interior2Json.Testimonials.PrevImg} alt=""/>
                                </button>
                                <button onClick={this.next} aria-label="Next Button">
                                    <NextImg src={this.props.interior2Json.Testimonials.NextImg} alt=""/>
                                </button>
                            </NextPrev>
                        </SliderWrapper>
                    </TestimonialsContainer>
                </Fade>
                
            </TestimonialsSection>
        );
    }
}


export default () => (
    <StaticQuery
        query={graphql`
            query {
                    interior2Json{
                        Testimonials{
                            TestimonialHeading
                            CardProfileLayout{
                                ProfilePic{
                                    childImageSharp{
                                        fluid(quality: 100){
                                        ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                                ProfileName
                                Designation
                                TestimonailHeading
                                Testimonial
                            }
                            PrevImg
                            NextImg
                        }
                    }
                }
            
        `}
        render={(data) => (
            <Testimonials interior2Json={data.interior2Json}/>
        )}
    />
  )